import { CanActivate } from '@angular/router'
import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'

@Injectable()
export class UserGuard implements CanActivate {
  constructor (private authService: AuthService) {
    if (!this.authService.isLogged()) {
      window.location.href = '/auth/login'
    }
  }

  canActivate () {
    return this.authService.isLogged()
  }
}
